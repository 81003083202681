import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form, Input, Message } from 'semantic-ui-react';

import type { Dispatch } from 'react';

import Info from 'src/Components/Case/Info/Info';
import { replaceObjectParameters } from 'src/Components/Utilities';
import { getEntityFieldsByEntityType } from 'src/Utilities/ticketTypes';

import type { ArchivalAction, ArchivalState } from './types';
import type { State } from 'src/types/initialState';
import type { Template, TemplateArchivalJson } from 'src/types/Template';

interface ArchialCustomerCardProps {
  archivalTemplate?: Template<TemplateArchivalJson>;
  localState: ArchivalState;

  dispatchState: Dispatch<ArchivalAction>;
}

const ArchialCustomerCard = ({
  archivalTemplate,
  localState: { additionalFields: additionalFieldsProp },
  dispatchState
}: ArchialCustomerCardProps) => {
  const { t } = useTranslation();
  const ticket = useSelector((state: State) =>
    state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)
  );
  const currentTicketType = useSelector((state: State) =>
    state.ticketTypes.find((ticketType) => ticketType.name === ticket?.taskType)
  );

  const selectedEntity = ticket?.entities[0]?.data;
  const pickedEntityFields = getEntityFieldsByEntityType(
    currentTicketType ? [currentTicketType] : [],
    selectedEntity?._type ?? ''
  ).filter((field) => field.value !== '_id');

  const renderAdditionalFields = () => {
    const additionalFields = archivalTemplate?.template?.additionalFields;
    if (additionalFields?.length) {
      return additionalFields.map((additionalField) => (
        <Form.Field>
          <label>{additionalField.label}</label>
          <Input
            value={additionalFieldsProp[additionalField.name]}
            onChange={(_e, { value }) => {
              dispatchState({
                type: 'SET_ADDITIONAL_FIELDS',
                payload: {
                  ...additionalFieldsProp,
                  [additionalField.name]: value
                }
              });
            }}
          />
        </Form.Field>
      ));
    }

    return <Message warning header="Warning" content={t('top_bar.advanced_actions.archive.alerts.empty_fields')} />;
  };

  useEffect(() => {
    const payload = {};
    archivalTemplate?.template?.additionalFields?.map((additionalField) => {
      payload[additionalField.name] = getAdditionalFieldDefaultValue(additionalField?.defaultValue);
    });
    dispatchState({
      type: 'SET_ADDITIONAL_FIELDS',
      payload
    });
  }, [archivalTemplate]);

  const getAdditionalFieldDefaultValue = (defaultValue: string) => {
    if (defaultValue.startsWith('{{')) {
      return replaceObjectParameters(defaultValue, {
        entity: selectedEntity,
        ticket
      });
    }
    return defaultValue;
  };

  return (
    <>
      <h3>{t('top_bar.advanced_actions.archive.labels.recipient')}</h3>

      {selectedEntity ? (
        <>
          <h5>{t('top_bar.advanced_actions.archive.labels.customer_info')}</h5>
          <Info fields={pickedEntityFields} values={selectedEntity} generalDisable={true} />
        </>
      ) : null}

      {archivalTemplate && !selectedEntity && (
        <>
          <h5>{t('top_bar.advanced_actions.archive.labels.sending_data')}</h5>

          <div className="archival__additionalFields_wrapper">{renderAdditionalFields()}</div>
        </>
      )}

      {!!Object.keys(additionalFieldsProp).find((fieldName) => !additionalFieldsProp[fieldName]) && (
        <Message
          warning
          header={t('GENERAL_WARNING')}
          content={t('top_bar.advanced_actions.archive.alerts.fields_empty')}
        />
      )}
    </>
  );
};

export default ArchialCustomerCard;
