import iziToast from 'izitoast';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Dropdown, Table } from 'semantic-ui-react';

import type { Dispatch } from 'react';

import EnvSettings from 'src/api/EnvSettings';

import type { ArchivalAction, ArchivalState } from './types';
import type { Attachment } from 'src/types/Ticket';

export interface SelectedFile {
  attachmentId: string;
  documentType: number;
}

interface ArchivalFilesProps {
  files: Attachment[];
  localState: ArchivalState;

  dispatchState: Dispatch<ArchivalAction>;
}

const ArchivalFiles = ({ files, localState: { selectedFiles }, dispatchState }: ArchivalFilesProps) => {
  const { t } = useTranslation();
  const [filesDocumentType, setFileDocumentType] = useState<SelectedFile[]>([]);
  const [massDocumntType, setMassDocumentType] = useState<number | undefined>(undefined);
  const {
    data: { documentTypes, allowedFileTypes }
  } = EnvSettings.getSettings().ESSI_ARCHIVAL_OPTIONS!;

  const toggleSelectAll = () => {
    if (selectedFiles.length === files.length) {
      dispatchState({ type: 'SET_SELECTED_FILES', payload: [] });
    } else {
      if (filesDocumentType.length === files.length) {
        dispatchState({
          type: 'SET_SELECTED_FILES',
          payload: filesDocumentType.filter((selectedFile) => {
            const file = files.find((file) => file.id === selectedFile.attachmentId);
            return allowedFileTypes.find((fileType) => file?.fileName.endsWith(fileType))
          })
        });
      } else {
        iziToast.warning({
          title: t('GENERAL_WARNING'),
          message: t('top_bar.advanced_actions.archive.alerts.missing_document_type'),
          position: 'bottomRight',
          timeout: 5000,
          icon: 'delete'
        });
      }
    }
  };

  return (
    <>
      <h3>{t('ATTACHMENTS')}</h3>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Dropdown
          search
          selection
          options={documentTypes.map((type) => ({
            text: type.name,
            value: type.id
          }))}
          value={massDocumntType}
          onChange={(_, data) => setMassDocumentType(data.value as number)}
        />

        <Button
          style={{ marginLeft: '10px' }}
          disabled={!massDocumntType}
          content={t('top_bar.advanced_actions.archive.buttons.apply_all')}
          onClick={() => {
            setFileDocumentType(
              files.map((file) => ({ attachmentId: file.id, documentType: massDocumntType as number }))
            );
            dispatchState({
              type: 'SET_SELECTED_FILES',
              payload: selectedFiles.map((selectedFile) => ({
                ...selectedFile,
                documentType: massDocumntType as number
              }))
            });
          }}
        />
      </div>

      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1} textAlign="center">
              <Checkbox checked={selectedFiles.length === files.length} onChange={toggleSelectAll} />
            </Table.HeaderCell>
            <Table.HeaderCell>{t('top_bar.advanced_actions.archive.labels.filename')}</Table.HeaderCell>
            <Table.HeaderCell>{t('top_bar.advanced_actions.archive.labels.document_type')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {files
            .filter((file) => !file.isQuarantined && !file.deprecated)
            .map((file) => {
              const currentFileDocumentType = filesDocumentType.find(
                (fileDocumentType) => fileDocumentType.attachmentId === file.id
              );

              return (
                <Table.Row disabled={!allowedFileTypes.find((fileType) => file.fileName.endsWith(fileType))}>
                  <Table.Cell textAlign="center">
                    <Checkbox
                      onChange={(_, { checked }) => {
                        if (currentFileDocumentType) {
                          if (checked) {
                            dispatchState({
                              type: 'SET_SELECTED_FILES',
                              payload: [...selectedFiles, currentFileDocumentType]
                            });
                          } else {
                            dispatchState({
                              type: 'SET_SELECTED_FILES',
                              payload: selectedFiles.filter((selectedFile) => selectedFile.attachmentId !== file.id)
                            });
                          }
                        } else {
                          // We can't choose attachment without selected document type
                          iziToast.warning({
                            title: t('GENERAL_WARNING'),
                            message: t('top_bar.advanced_actions.archive.alerts.select_document'),
                            position: 'bottomRight',
                            timeout: 5000,
                            icon: 'delete'
                          });
                        }
                      }}
                      checked={!!selectedFiles.find((selectedFile) => selectedFile.attachmentId === file.id)}
                    />
                  </Table.Cell>
                  <Table.Cell>{file.fileName}</Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      fluid
                      search
                      selection
                      placeholder={t('top_bar.advanced_actions.archive.labels.not_chosen')}
                      options={documentTypes.map((type) => ({ text: type.name, value: type.id }))}
                      value={currentFileDocumentType?.documentType}
                      onChange={(_, data) => {
                        setFileDocumentType([
                          ...filesDocumentType.filter((fileDocumentType) => fileDocumentType.attachmentId !== file.id),
                          { attachmentId: file.id, documentType: data.value as number }
                        ]);

                        // In case file already selected we need to change it's document type under state
                        if (data.value && selectedFiles.find((selectedFile) => selectedFile.attachmentId === file.id)) {
                          dispatchState({
                            type: 'SET_SELECTED_FILES',
                            payload: [
                              ...selectedFiles.filter((selectedFile) => selectedFile.attachmentId !== file.id),
                              { attachmentId: file.id, documentType: data.value as number }
                            ]
                          });
                        }
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </>
  );
};

export default ArchivalFiles;
